import { COOKIE_GDPR_LEVEL, GDPR_LEVELS, TYPES, DOMAIN } from '../constants/constants';
import { initPortalId, deletePortalId } from '../portal-id/portal-id';
import { sendGdprLevelEvents } from '../send-gdpr-level/send-gdpr-level';
export default {
	data() {
		return {
			hasGdpr: false,
			GDPR_LEVELS,
			showDetails: false,
			gdprLevel: GDPR_LEVELS.HIGH
		};
	},
	props: {
		details: {
			required: false
		},
		disclaimerUrl: {
			type: String,
			required: true
		},
		siteName: {
			type: String,
			default: ' OTP Bank'
		},
		actualGdprLevel: {
			required: true
		},
		mainDescPrefix: {
			type: String,
			default: null
		},
		type: {
			type: String,
			required: true
		}
	},
	watch: {
		details(val) {
			this.showDetails = val;
		},
		showDetails(val) {
			this.$emit('show-details', val);
		}
	},
	computed: {
		isMobile() {
			return this.$resize && this.$mq.below(767);
		},
		chooseMainDescPrefix() {
			const lang = this.$i18n.locale;

			if (this.mainDescPrefix && lang === 'hu') {
				return this.mainDescPrefix;
			} else {
				return this.$t('main-desc-prefix');
			}
		}
	},
	methods: {
		setGdprLevel(gdprLevel) {
			this.gdprLevel = gdprLevel;
			this.handleGdprLevel();
		},
		setJuniorGdprLevel() {
			this.gdprLevel = GDPR_LEVELS.JUNIOR;
			this.handleGdprLevel();
		},
		handleGdprLevel() {
			const cookieOptions = {
				path: '/',
				expires: '2Y',
				secure: true,
				SameSite: 'strict'
			};
			const isOtp = window.location.hostname.endsWith('otpbank.hu');
			const domain = isOtp && DOMAIN;
			if (domain) {
				cookieOptions.domain = DOMAIN;
			}
			this.$cookie.set(COOKIE_GDPR_LEVEL, this.gdprLevel, cookieOptions);
			switch (this.gdprLevel) {
				case GDPR_LEVELS.JUNIOR:
				case GDPR_LEVELS.LOW:
				case GDPR_LEVELS.MEDIUM:
					deletePortalId();
					break;
				case GDPR_LEVELS.HIGH:
					initPortalId(this.type == TYPES.PORTAL);
					break;
				default:
					deletePortalId();
			}
			// DEPRECATED
			if (typeof window.onGdprConsentAccepted == 'function') {
				window.onGdprConsentAccepted(this.gdprLevel);
			}
			sendGdprLevelEvents(this.gdprLevel);
			this.$closeModal('gdpr_modal');
		},
		setHighGdprLevel() {
			this.gdprLevel = GDPR_LEVELS.HIGH;
			this.handleGdprLevel();
		},
		setLowGdprLevel() {
			this.gdprLevel = GDPR_LEVELS.LOW;
			this.handleGdprLevel();
		},
		showDetailsContainer() {
			this.showDetails = true;
		}
	},
	created() {
		this.$modalEventBus.$on('modal-click-outside', modalId => {
			if (modalId == 'gdpr_modal') {
				this.setLowGdprLevel();
			}
		});
		if (this.details) {
			this.showDetails = this.details;
		}
		this.gdprLevel = this.actualGdprLevel || GDPR_LEVELS.HIGH;
	}
};
