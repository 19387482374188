import Vue from 'vue';
const PORTAL_ID_RELATIVE_URL = '/apps3/portalid/portalid';
const PORTAL_ID_URL = `https://www.otpbank.hu${PORTAL_ID_RELATIVE_URL}?callback=true`;
export const COOKIE_PORTAL_ID_NAME = 'portalId';
const COOKIE_PORTAL_ID_VERSION_NAME = 'portalIdVersion';
const PORTAL_ID_VERSION = 2;
const MIN_DATE = '01 Jan 1970 00:00:00 UTC';
import { COOKIE_GDPR_LEVEL, GDPR_LEVELS, DOMAIN } from '../constants/constants';
window.savePortalId = portalId => {
	Vue.cookie.set(COOKIE_PORTAL_ID_NAME, portalId, { path: '/', expires: '2Y', secure: true, SameSite: 'strict' });
};
export const sendPortaldIdReadyEvents = portalIdCookie => {
	setTimeout(() => {
		const event = new CustomEvent('portal-id-ready', { detail: portalIdCookie });
		window.dispatchEvent(event);
	});
};
const checkPortalId = (retry, loadInProgress, isPortal) => {
	if (!retry) {
		return;
	}
	const portalIdCookie = Vue.cookie.get(COOKIE_PORTAL_ID_NAME);
	const portalIdVersionCookie = Vue.cookie.get(COOKIE_PORTAL_ID_VERSION_NAME);
	if (portalIdCookie && (!isPortal || portalIdVersionCookie == PORTAL_ID_VERSION)) {
		sendPortaldIdReadyEvents(portalIdCookie);
	} else {
		if (!loadInProgress) {
			let s = document.createElement('script');
			s.type = 'text/javascript';
			s.src = isPortal ? PORTAL_ID_RELATIVE_URL : PORTAL_ID_URL;
			let head = document.getElementsByTagName('head')[0];
			head.insertBefore(s, head.firstChild);
		}
		setTimeout(() => checkPortalId(--retry, true, isPortal), 500);
	}
};

const expireCookie = cookie => {
	document.cookie = `${cookie}=; expires=${MIN_DATE}; path=/; domain=${DOMAIN}`;
};
export const initPortalId = (isPortal = false) => {
	let gdprLevel = Vue.cookie.get(COOKIE_GDPR_LEVEL);
	if (gdprLevel == GDPR_LEVELS.HIGH) {
		checkPortalId(20, false, isPortal);
	} else {
		sendPortaldIdReadyEvents();
	}
};

export const deletePortalId = () => {
	const isOtp = window.location.hostname.endsWith('otpbank.hu');
	if (isOtp) {
		expireCookie(COOKIE_PORTAL_ID_NAME);
		expireCookie(COOKIE_PORTAL_ID_VERSION_NAME);
	} else {
		Vue.cookie.delete(COOKIE_PORTAL_ID_NAME);
		Vue.cookie.delete(COOKIE_PORTAL_ID_VERSION_NAME);
	}
	sendPortaldIdReadyEvents();
};
