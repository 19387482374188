<template lang="pug">
.gdpr-modal-toggle-container
	.gdpr-levels
		.gdpr-levels__radios
			vue-radio-group(v-model="localGdprLevel", :options="[ { key: 1, value: $t('gdpr-level1.title'), ariaDescribedBy: $t('gdpr-level1.a11y') }, { key: 2, value: $t('gdpr-level2.title'), ariaDescribedBy: $t('gdpr-level2.a11y') }, { key: 3, value: $t('gdpr-level3.title'), ariaDescribedBy: $t('gdpr-level3.a11y') }, ]", :legend="$t('please-setting-title')", :isLegendHidden="true", aria-describedby="gdpr_setting_title_hidden")
		section.gdpr-levels__details(name="gdpr_selected_cookie")
			h3#gdpr_selected_cookie.sr-only {{ $t('cookie-selected-hidden') }}
			.gdpr-level-content(v-if="localGdprLevel == GDPR_LEVELS.LOW")
				h4.gdpr-level-content__title.sf-heading-3 {{ $t('gdpr-level1.sub-title') }}
				p.sf-text {{ $t('gdpr-level1.desc') }}
				p.gdpr-used-cookies.sf-text: strong {{ $t('gdpr-level1.used-cookies') }}
				slot(name="level1List")
			.gdpr-level-content(v-if="localGdprLevel == GDPR_LEVELS.MEDIUM")
				h4.gdpr-level-content__title.sf-heading-3 {{ $t('gdpr-level2.sub-title') }}
				p.sf-text {{ $t('gdpr-level2.desc') }}
				p.gdpr-used-cookies.sf-text: strong {{ $t('gdpr-level2.used-cookies') }}
				slot(name="level2List")
			.gdpr-level-content(v-if="localGdprLevel == GDPR_LEVELS.HIGH")
				h4.gdpr-level-content__title.sf-heading-3 {{ $t('gdpr-level3.sub-title') }}
				p.sf-text {{ $t('gdpr-level3.desc') }}
				p.gdpr-used-cookies.sf-text: strong {{ $t('gdpr-level3.used-cookies') }}
				slot(name="level3List")
			p.sf-text
				a.sf-link(:href="disclaimerUrl", target="_blank") {{ $t('gdpr-download-pdf') }}
	button.sf-btn(ref="button", @click="handleGdprLevel") {{ $t('gdpr-submit-btn') }}
</template>
<script>
import VueRadioGroup from '@ui-common/vue-radio-group';
import { GDPR_LEVELS } from '../../common/js/constants/constants';
import messages from './gdpr-details-lang.json';
const ARIA_VALUE_TEXTS = {
	[GDPR_LEVELS.LOW]: 'Legszűkebb szint: Alap működés',
	[GDPR_LEVELS.MEDIUM]: 'Közepes szint: Statisztikai sütik',
	[GDPR_LEVELS.HIGH]: 'Legbővebb szint: Statisztikai és reklám célú sütik'
};
export default {
	i18n: {
		// `i18n` option, setup locale info for component
		messages
	},
	data() {
		return {
			GDPR_LEVELS,
			localGdprLevel: null
		};
	},
	props: {
		disclaimerUrl: {
			type: String,
			required: true
		}
	},
	components: {
		VueRadioGroup
	},
	computed: {
		isMobile() {
			return this.$resize && this.$mq.below(767);
		},
		ariaValueText() {
			return ARIA_VALUE_TEXTS[this.localGdprLevel];
		}
	},
	methods: {
		handleGdprLevel() {
			if (this.localGdprLevel) {
				this.$emit('on-gdpr-change', this.localGdprLevel);
			}
		}
	},
	created() {
		if (this.details) {
			this.showDetails = this.details;
		}
	}
};
</script>
<style lang="scss">
.gdpr-modal-toggle-container .gdpr_setting_title {
	margin-bottom: 12px;
}

.gdpr-level-content .gdpr-level-content__title {
	font-size: 18px;
	font-weight: bold;
	line-height: 32px;
}

.gdpr-level-content .gdpr-used-cookies {
	margin-bottom: 14px;
}

.gdpr-levels {
	@media (min-width: 996px) {
		display: flex;
	}
}

.gdpr-levels__radios {
	margin-bottom: 20px;

	@media (min-width: 996px) {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		margin-bottom: 0;
		margin-right: 20px;
		width: 50%;
	}
}

.gdpr-levels__radios .sf-radio-group__pair {
	display: block;
	width: 100%;
}

.gdpr-levels__details {
	@media (min-width: 996px) {
		width: 50%;
	}
}

.gdpr-levels__details .sf-text:last-of-type {
	margin-bottom: 32px;
}
</style>
