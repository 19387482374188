import VueButton from '@ui-common/vue-button';
import VueRadioGroup from '@ui-common/vue-radio-group';
import { COOKIE_GDPR_LEVEL, GDPR_LEVELS, TYPES } from '../constants/constants';
import { COOKIE_PORTAL_ID_NAME, sendPortaldIdReadyEvents } from '../portal-id/portal-id';
import { sendGdprLevelEvents } from '../send-gdpr-level/send-gdpr-level';

export default {
	data() {
		return {
			gdprLevel: null,
			details: false,
			hasDetails: false
		};
	},
	props: {
		disclaimerUrl: {
			type: String,
			required: true
		},
		siteName: {
			type: String,
			required: true
		},
		mainDescPrefix: {
			type: String,
			default: null
		},
		closable: {
			type: Boolean,
			default: false
		},
		type: {
			type: String,
			default: TYPES.SUBSITE,
			validator(val) {
				return Object.values(TYPES).includes(val);
			}
		}
	},
	components: {
		VueButton,
		VueRadioGroup
	},
	computed: {
		hasGdpr() {
			return !!this.gdprLevel || this.details;
		}
	},
	methods: {
		setModalTitle(details) {
			this.hasDetails = details;
		},
		handleCoookie() {
			const gdprLevel = this.$cookie.get(COOKIE_GDPR_LEVEL);
			if (gdprLevel) {
				this.gdprLevel = parseInt(gdprLevel, 10);
				sendGdprLevelEvents(this.gdprLevel);
				if (this.gdprLevel == GDPR_LEVELS.HIGH) {
					const portalId = this.$cookie.get(COOKIE_PORTAL_ID_NAME);
					sendPortaldIdReadyEvents(portalId);
				} else {
					sendPortaldIdReadyEvents();
				}
			}
		}
	},
	created() {
		this.$modalEventBus.$on('modal-opened', (modalId, payload) => {
			if (modalId == 'gdpr_modal') {
				if (payload && payload.gdprDetails) {
					const gdprLevel = this.$cookie.get(COOKIE_GDPR_LEVEL);
					if (gdprLevel) {
						this.details = payload.gdprDetails;
					} else {
						this.details = false;
					}
				} else {
					this.details = false;
				}
				window.gdprOpen = true;
			}
		});
		this.$modalEventBus.$on('modal-closed', modalId => {
			if (modalId == 'gdpr_modal') {
				window.dataLayer = window.dataLayer || [];
				window.dataLayer.push({ event: 'modalClosed' });
			}
		});
		this.handleCoookie();
	}
};
