<template lang="pug">
.gdpr-modal.modal-body
	h2#gdpr_modal.sf-heading-2(tabindex="-1")
	p#gdpr_modal_desc.sf-text {{ chooseMainDescPrefix }}{{ siteName }}
		span(v-if="type === 'portal'", v-html="$t('main-desc-portal')")
		span(v-else v-html="$t('main-desc')")
	.gdpr-controll-bar(v-if="!showDetails")
		button.sf-btn.gdrp-btn__submit(ref="button", @click="setHighGdprLevel") {{ $t('gdpr-submit-all-btn') }}
		.gdpr-controll-buttons
		button.sf-btn.sf-btn--link.gdrp-btn__details(@click="showDetailsContainer") {{ $t('cookie-settings-link') }}
		a.sf-btn.sf-btn--link.gdrp-btn__pdf(:href="disclaimerUrl", target="_blank") {{ $t('gdpr-download-pdf') }}
	gdpr-details(v-if="showDetails", :gdpr-level="gdprLevel", :disclaimer-url="disclaimerUrl", :actual-gdpr-level="$attrs['actual-gdpr-level']", @on-gdpr-change="setGdprLevel")
		slot(name="level1List", slot="level1List")
		slot(name="level2List", slot="level2List")
		slot(name="level3List", slot="level3List")
</template>
<script>
import gdprModalContentBase from '../../common/js/gdpr-base/gdpr-modal-content-base';
import gdprDetails from '../gdpr-details/gdpr-details.vue';
import messages from './gdpr-modal-content-lang.json';
export default {
	extends: gdprModalContentBase,
	components: {
		gdprDetails
	},
	i18n: {
		messages
	}
};
</script>
<style lang="scss">
.gdpr-modal {
	border: 0;
	display: block;
	flex: 1 1 auto;
	padding: 0;
	position: relative;
}

.gdpr-controll-bar {
	@media (max-width: 767px) {
		text-align: center;
	}
}

.gdpr-controll-bar > * {
	@media (max-width: 767px) {
		margin-left: auto;
		margin-right: auto;
	}
}

.gdrp-btn__submit.sf-btn {
	display: block;
	margin-bottom: 24px;
}

.gdrp-btn__details.sf-btn {
	display: block;
	font-weight: 600;
	margin-bottom: 24px;

	&:hover {
		text-decoration: underline;
	}

	@media (min-width: 996px) {
		display: inline-block;
		margin-bottom: 0;
		margin-right: 32px;
	}
}

.gdrp-btn__pdf.sf-btn {
	font-weight: 600;
}

.gdrp-btn__pdf.sf-btn:hover {
	text-decoration: underline;
}
</style>
