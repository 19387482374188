import 'document-register-element/build/document-register-element';
import 'custom-event-polyfill';
import Vue from 'vue';
import VueCookie from 'vue-cookie';
import vueCustomElement from 'vue-custom-element';
import VueModal from '@ui-common/vue-modal';
import eventBus from './common/js/event-bus/event-bus';
import GdprConsent from './components/gdpr-consent/gdpr-consent';
import { mediaQueries } from './common/js/media-query/media-query';
import VueI18n from 'vue-i18n';
import isBot from 'isbot-fast';
if (!isBot(navigator.userAgent)) {
	Vue.use(VueCookie);
	Vue.use(vueCustomElement);
	Vue.use(mediaQueries);
	Vue.use(VueI18n);
	Vue.use(VueModal);
	const locale = document.documentElement.lang || 'hu';
	const i18n = new VueI18n({
		locale
	});
	eventBus.$on('openModal', (e, modalId, options) => {
		Vue.openModal(e, modalId, options);
	});
	Vue.prototype.closeModal = (modalId, options) => {
		eventBus.$emit('closeModal', modalId, options);
	};
	Vue.customElement('gdpr-consent', GdprConsent, {
		beforeCreateVueInstance(RootComponentDefinition) {
			RootComponentDefinition.mediaQueries = mediaQueries;
			RootComponentDefinition.i18n = i18n;
			return RootComponentDefinition;
		}
	});
}
