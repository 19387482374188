<template lang="pug">
#gdpr_consent
	VueModal.gdpr-modal(modal-id="gdpr_modal", :open="!hasGdpr", role="dialog", :closable="closable", :close-btn-visible="false")
		h2#gdpr_modal.sf-heading-1.gdpr-modal-heading1(slot="modal-header", tabindex="-1")
			template(v-if="hasDetails") {{ $t('title-details') }}
			template(v-else) {{ $t('title') }}
		gdpr-modal-content(slot="modal-body", :type="type", :actual-gdpr-level.number="gdprLevel", :details="details", :disclaimer-url="disclaimerUrl", :site-name="siteName", ref="gdprModalContent", @show-details="setModalTitle", :main-desc-prefix="mainDescPrefix")
			slot(name="level1List", slot="level1List")
			slot(name="level2List", slot="level2List")
			slot(name="level3List", slot="level3List")
			VueButton(@click="$changeModalWidth('modal-id', 'narrow')") Modal Change
			VueButton(@click="$hideModalHeader('modal-id')") Modal Hide Header
			VueButton(@click="$showModalHeader('modal-id')") Modal Show Header
		VueButton(@click="$openModal($event, 'modal-id')") Modal Open
</template>
<script>
import GdprModalContent from '../gdpr-modal-content/gdpr-modal-content.vue';
import messages from './gdpr-modal-lang.json';
import gdprConsentBase from '../../common/js/gdpr-base/gdpr-consent-base';

export default {
	extends: gdprConsentBase,
	i18n: {
		messages
	},
	components: {
		GdprModalContent
	}
};
</script>
<style lang="scss">
.gdpr-modal {
	@import '@ui-common/sf-style-utils/lib/_list.scss';
	@import '@ui-common/sf-style-utils/lib/_link.scss';
	@import '@ui-common/sf-style-utils/lib/_paragraph.scss';
	@import '@ui-common/sf-style-utils/lib/_headings.scss';

	.sf-modal-heading > *[class^='sf-heading'] {
		font-weight: 700;
		line-height: 32px;
	}
}

.gdpr-modal .sf-modal__body {
	margin: 0;
}

.gdpr-modal-heading1 {
	font-weight: 700;
	line-height: 32px;
}
</style>
