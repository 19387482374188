export const COOKIE_GDPR_LEVEL = 'gdpr_level';
export const GDPR_LEVELS = {
	LOW: 1,
	MEDIUM: 2,
	HIGH: 3,
	JUNIOR: 4
};
export const TYPES = {
	PORTAL: 'portal',
	SUBSITE: 'subsite'
};
export const DOMAIN = '.otpbank.hu';
